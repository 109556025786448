import { AppState, QueryMetaSuccess, SaveStatus } from './store';
import { QueryStatus } from 'common/explore_grid/types';
import { analysisSuccess, querySuccess, getUnAnalyzedAst, viewContextFromQuery } from '../lib/selectors';
import { CompilationStatus } from 'common/types/compiler';
import { Reducer } from 'redux';
import { Action } from './actions';
import { builders as BuildRemoteStatus, selectors as SelectRemoteStatus } from './statuses';
import { some, none } from 'ts-option';
import { CollocationStatus } from 'common/core/collocation';
import { addOverridesToVariables, sortClientContextVariables } from 'common/core/client_context_variables';
import { some as _some } from 'lodash';
import _ from 'lodash';

export default (withLogging = true): Reducer<AppState> => {
  // we never allow the state parameter to be undefined.
  // @ts-expect-error TS(2322) FIXME: Type '(state: AppState, action: Action) => AppStat... Remove this comment to see the full error message
  return (state: AppState, action: Action): AppState => {
    // console.logs left here for when react-devtools
    // crashes and becomes unusable (usually only happens
    // on days ending in y)
    if (withLogging) console.log('action', action, state);
    const doit = (): AppState => {
      switch (action.type) {
        case 'FETCH_GUIDANCE_SUCCEEDED': {
          return {
            ...state,
            approvalsGuidance: action.guidance
            };
        }
        case 'FETCH_GUIDANCE_FAILED': {
          return {
            ...state,
            approvalsGuidance: undefined
            };
        }
        case 'SCOPE_CHANGED': {
          return {
            ...state,
            scope: some(action.scope)
          };
        }
        case 'SET_QUERY_TEXT': {
          const compilationInProgress = state.query.compilationResult.match({
            none: () => false,
            some: (r) => r.type === CompilationStatus.Started
          });

          return { ...state, query: {
            ...state.query,
            text: some(action.text),
            typedWhileCompilationInProgress: state.query.typedWhileCompilationInProgress || compilationInProgress
          }};
        }
        case 'COMPILATION_STAGED': {
          return {
            ...state, query: {
              ...state.query,
              compilationResult: some(action.result)
            },
            remoteStatusInfo: some(BuildRemoteStatus.cannotCompileQueryBecause(action.reason))
          };
        }
        case 'COMPILATION_STARTED': {
          return {
            ...state, query: {
              ...state.query,
              compilationResult: some(action.result)
            },
            remoteStatusInfo: some(BuildRemoteStatus.compilingCandidateQuery())
          };
        }
        case 'COMPILATION_FAILED': {
          const remoteStatusInfo = some(BuildRemoteStatus.cannotRunQuery('compilation_failed'));
          const stateWithRSI = { ...state, remoteStatusInfo };

          // this ref check is to make sure that the compilation that is pending is still
          // the one that triggered this action. ie: if the user updates the AST twice in quick succession,
          // the second `COMPILATION_STARTED` action will update the state, and we'll only care about the
          // compilation result for that action, rather than the first one. So we only put the compilation result
          // in the state if we're actually waiting on it. This way we avoid displaying the incorrect result
          // for the initial request that was fired.
          return state.query.compilationResult.map(cr => {
            if (cr.type === 'started' && cr.ref === action.ref) {
              // don't overwrite the user's text query if they've continued typing since the compile message was sent
              const text = state.query.typedWhileCompilationInProgress
                ? state.query.text
                : action.result.text.orElseValue(state.query.text);
              return { ...stateWithRSI, query: {
                ...state.query,
                text: text,
                compilationResult: some({
                  ...action.result,
                  // If the new state doesn't have ViewContext, use the previous one.
                  views: action.result.views.orElse(() => viewContextFromQuery(state.query)),
                  // If the new state doesn't have an unanalyzed, use the previous one.
                  unanalyzed: action.result.unanalyzed.orElseValue(getUnAnalyzedAst(state.query))
                }),
                typedWhileCompilationInProgress: false
              }};
            }
            return stateWithRSI;
          }).getOrElseValue(stateWithRSI);
        }
        case 'COMPILATION_SUCCEEDED': {
          const remoteStatusInfo = querySuccess(state.query.queryResult).map(qr => {
            const cr = action.result;
            // Ima be real, I don't actually understand why this is important or what it's
            // actually even checking. But it definitely had to be a thing.
            if (cr.runnable === qr.compiled.runnable) {
              return BuildRemoteStatus.queryRanSuccessfully(none);
            } else {
              return BuildRemoteStatus.canRunCompiledQuery();
            }
          });

          return state.query.compilationResult.map(cr => {
            if (cr.type === CompilationStatus.Started && cr.ref === action.ref) {
              // don't overwrite the user's text query if they've continued typing since the compile message was sent
              const text = state.query.typedWhileCompilationInProgress ? state.query.text : action.result.text.orElseValue(state.query.text);
              return { ...state, query: {
                ...state.query,
                paginationState: {
                  ...state.query.paginationState,
                  pageSizePendingRun: some(action.result.pageSize), // dsmapi has calculated the offset/limit on the 'runnable' query, and is keeping us in sync with that setting
                  currentPagePendingRun: some(action.result.currentPage)
                },
                text: text,
                compilationResult: some(action.result),
                typedWhileCompilationInProgress: false
              }, remoteStatusInfo };
            }
            return { ...state, remoteStatusInfo };
          }).getOrElseValue({ ...state, remoteStatusInfo });
        }
        case 'ANALYSIS_FAILED': {
          return { ...state,
            query: {
              ...state.query,
              analysisResult: some(action.result)
            }
          };
        }
        case 'ANALYSIS_SUCCEEDED': {
          return { ...state,
            query: {
              ...state.query,
              analysisResult: some(action.result)
            }
          };
        }
        case 'COLLOCATION_NOT_NEEDED': {
          return { ...state, collocationInfo: some({
            collocationStatus: CollocationStatus.NotNeeded,
            collocationJobId: none,
            collocationResult: none,
            joinTargets: action.joinTargets
          })};
        }
        case 'COLLOCATION_NEEDED': {
          return { ...state, collocationInfo: some({
            collocationStatus: CollocationStatus.Missing,
            collocationJobId: none,
            collocationResult: none,
            joinTargets: action.joinTargets
          }), remoteStatusInfo: some(BuildRemoteStatus.compiledQueryNeedsCollocation()) };
        }
        case 'COLLOCATION_IN_PROGRESS': {
          return { ...state, collocationInfo: some({
            collocationStatus: CollocationStatus.InProgress,
            collocationJobId: some(action.jobId),
            collocationResult: none,
            joinTargets: action.joinTargets
          }), remoteStatusInfo: some(BuildRemoteStatus.collocatingCompiledQuery()) };
        }
        case 'COLLOCATION_COMPLETED': {
          return { ...state, collocationInfo: some({
            collocationStatus: CollocationStatus.Completed,
            collocationJobId: none,
            collocationResult: none,
            joinTargets: action.joinTargets
          }), remoteStatusInfo: some(BuildRemoteStatus.canRunCompiledQuery()) };
        }
        case 'COLLOCATION_FAILED': {
          return { ...state, collocationInfo: some({
            collocationStatus: CollocationStatus.Missing,
            collocationJobId: none,
            collocationResult: some(action.response),
            joinTargets: action.joinTargets
          }), remoteStatusInfo: some(BuildRemoteStatus.cannotRunQueryBecauseCollocation(action.response)) };
        }
        case 'QUERY_STARTED': {
          return {
            ...state,
            clientContextInfo: {
              variables: addOverridesToVariables(state.clientContextInfo.variables, state.clientContextInfo.pendingOverrides),
              pendingOverrides: {}
            },
            query: {
              ...state.query,
              isQueryInProgress: true,
            },
            remoteStatusInfo: some(BuildRemoteStatus.runningCompiledQuery(action.successTab))
          };
        }
        case 'QUERY_SUCCEEDED': {
          const pageSize = state.query.paginationState.pageSizePendingRun.getOrElseValue(state.query.paginationState.pageSize);
          const currentPage = state.query.paginationState.currentPagePendingRun.getOrElseValue(state.query.paginationState.currentPage);
          const currentAnalysisResult = analysisSuccess(state.query.analysisResult);
          return {
            ...state,
            columns: action.columns,
            query: {
            ...state.query,
            paginationState: {
              pageSize,
              currentPage,
              pageSizePendingRun: none,
              currentPagePendingRun: none
            },
            isQueryInProgress: false,
            queryResult: some({
              type: QueryStatus.QUERY_SUCCESS,
              relevanceId: action.relevanceId,
              compiled: action.compiled,
              analyzed: currentAnalysisResult, // This does not match the current way of attaching this.
              rows: action.rows,
              meta: { type: QueryStatus.QUERY_META_IN_PROGRESS }
            })
          },
          remoteStatusInfo: some(BuildRemoteStatus.queryRanSuccessfully(
            SelectRemoteStatus.runningCompiledQuery(state.remoteStatusInfo).flatMap(rsi => rsi.successTab)
          ))
        };
        }
        case 'QUERY_META_SUCCEEDED': {
          return state.query.queryResult.flatMap(qr => {
            if (qr.type === 'query_success' && qr.relevanceId === action.relevantTo) {
              const meta: QueryMetaSuccess = {
                type: QueryStatus.QUERY_META_SUCCESS,
                rowCount: action.rowCount,
                fromAst: action.fromAst,
                clientContextVariables: state.clientContextInfo.variables
              };

              const newState: AppState = {
                ...state, query: {
                  ...state.query,
                  queryResult: some({
                    ...qr,
                    meta
                  })
                }
              };

              return some(newState);
            } else {
              return none;
            }
          }).getOrElseValue(state);
        }
        case 'QUERY_FAILED': {
          return {...state, query: {
            ...state.query,
            isQueryInProgress: false,
            queryResult: some({
              type: QueryStatus.QUERY_FAILURE,
              details: action.details,
            })
          }};
        }
        case 'SET_VIEW_QUERY_STRING': {
          return { ...state, view: {
            ...state.view,
            queryString: action.queryString
          }};
        }
        case 'LOCATION_CHANGED': {
          return {
            ...state,
            locationParams: action.location
          };
        }
        case 'TOGGLE_SIDEBAR': {
          return {
            ...state,
            isSidebarOpen: !state.isSidebarOpen
          };
        }
        case 'SET_OPEN_MODAL': {
          return {
            ...state,
            openModal: {
              type: action.modal,
              modalData: action.data
            }
          };
        }
        case 'SET_TOAST': {
          return {
            ...state,
            toastState: action.toastState
          };
        }
        case 'UNDOCK_EDITOR': {
          return {
            ...state,
            undocked: action.undocked
          };
        }
        case 'SET_MODAL_TARGET_WINDOW': {
          return {
            ...state,
            modalTargetWindow: action.modalTargetWindow === undefined ? null : action.modalTargetWindow
          };
        }
        case 'SET_DEFAULT_QUERY_TEXT': {
          return {
            ...state,
            saveInfo: {
              ...state.saveInfo,
              defaultQuery: action.queryText
            }
          };
        }
        case 'CLEAR_SAVE_STATUS': {
          return {
            ...state,
            saveInfo: {
              ...state.saveInfo,
              saveStatus: SaveStatus.IDLE
            }
          };
        }
        case 'SAVE_VIEW_STARTED': {
          return {
            ...state,
            saveInfo: {
              ...state.saveInfo,
              saveStatus: SaveStatus.SAVING
            }
          };
        }
        case 'SAVE_VIEW_SUCCEEDED': {
          return {
            ...state,
            view: action.savedView,
            saveInfo: {
              ...state.saveInfo,
              saveStatus: SaveStatus.SAVED
            }
          };
        }
        case 'SAVE_VIEW_FAILED': {
          return {
            ...state,
            saveInfo: {
              ...state.saveInfo,
              saveStatus: SaveStatus.ERRORED
            }
          };
        }
        case 'APPLY_CLICKED': {
          return {
            ...state,
            applyInfo: {
              ...state.applyInfo,
              lastClickedApply: some(action.time)
            }
          };
        }
        case 'COLUMN_UPDATED': {
          return {
            ...state,
            columns: [...state.columns.filter(c => c.fieldName != action.updatedColumn.fieldName), action.updatedColumn]
          };
        }
        case 'COLUMNS_UPDATED': {
          return {
            ...state,
            columns: action.columns
          };
        }
        case 'ADD_NEW_PARAMETER': {
          return {
            ...state,
            clientContextInfo: {
              ...state.clientContextInfo,
              variables: sortClientContextVariables([action.parameter, ...state.clientContextInfo.variables], false)
            }
          };
        }
        case 'DELETE_PARAMETER': {
          return {
            ...state,
            clientContextInfo: {
              ...state.clientContextInfo,
              variables: state.clientContextInfo.variables.filter(v => v.name != action.parameterName)
            }
          };
        }
        case 'REPLACE_PARAMETER_LIST': {
          const newList = action.parameterList.map((ccvc) => {
            // if the variable hasn't changed type, preserve any override (test value) that's been set
            // as long as that override is also available in the (possibly new) suggestedValues list
            const oldParam = state.clientContextInfo.variables.find(old => old.name === ccvc.name && old.dataType === ccvc.dataType);
            const override = (ccvc.suggestedValues && ccvc.suggestedValues.valueList.findIndex((val) => val.value === oldParam?.overrideValue) < 0) ?
              undefined : oldParam?.overrideValue;
            return {...ccvc, inherited: false, viewId: action.viewId, overrideValue: override};
          });
          // grab test values for any parameters that haven't changed type
          const inheritedVars = state.clientContextInfo.variables.filter((ccv) => {
            return ccv.inherited;
          });
          return {
            ...state,
            clientContextInfo: {
              ...state.clientContextInfo,
              variables: sortClientContextVariables([...newList, ...inheritedVars], false),
              pendingOverrides: {} // query gets run after replacing parameter list, clear any pending-but-unapplied overrides because they may be invalid
            }
          };
        }
        case 'REPLACE_PARAMETER_OVERRIDES': {
          return {
            ...state,
            clientContextInfo: {
              pendingOverrides: {},
              variables: action.parameterList
            }
          };
        }
        case 'CLEAR_PARAMETER_OVERRIDES': {
          return {
            ...state,
            clientContextInfo: {
              pendingOverrides: {},
              variables: _.cloneDeep(state.clientContextInfo.variables).map(v => ({ ...v, overrideValue: undefined }))
            }
          };
        }
        case 'SOQL_EDITOR_LOADED': {
          return {
            ...state,
            soqlEditorInfo: { editorInstance: some(action.editor)}
          };
        }
        case 'UPDATE_UNDO_REDO': {
          return {
            ...state,
            applyInfo: {
              ...state.applyInfo,
              lastClickedApply: some(new Date())
            },
            undoRedoInfo: {
              undo: action.undo,
              redo: action.redo,
              justApplied: some(action.justApplied)
            }
          };
        }
        case 'UPDATE_PARAMETER_PENDING_OVERRIDE': {
          const currentParameter  = state.clientContextInfo.variables.find(v => v.name === action.parameter.name);
          const overrideChanged = (
            state.clientContextInfo.pendingOverrides[action.parameter.name]?.override ||
            currentParameter?.overrideValue ||
            currentParameter?.defaultValue
          ) !== action.override.override;

          const newPendingOverrides = {
            ...state.clientContextInfo.pendingOverrides,
            [action.parameter.name]: action.override
          };
          const getStatus = () => {
            if (!overrideChanged) return state.remoteStatusInfo;
            if (_some(newPendingOverrides, ov => !ov.isValid) ) return some(BuildRemoteStatus.cannotRunQuery('invalid_parameter_overrides'));
            return some(BuildRemoteStatus.runnableParameterChanges());
          };
          return {
            ...state,
            clientContextInfo: {
              ...state.clientContextInfo,
              pendingOverrides: newPendingOverrides
            },
            remoteStatusInfo: getStatus()
          };
        }
        default:
          return state;
      }
    };
    const next = doit();
    if (withLogging) console.log('new state', next);
    return next;
  };
};
